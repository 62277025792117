import React from 'react';

function ExploreBySize() {
  return (
    <div>
      <h1>Explore by Size</h1>
      {/* More content here */}
    </div>
  );
}

export default ExploreBySize;
